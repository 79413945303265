$__primarycolor:#012C90;
$__primarycolorhover:#fe820e;
$__secondarycolor:#212B36;

$__white:#fff;
$__gray:#333;
$__gray__dark:#6E6B7B;
$__sandstone: #637381;
$__goose-gray: #cccccc;
$__light__gooses: #F8F8F8;
$__light__goosesvalue: #F3F2F7;
$__light-goose-grays:#E9ECEF;
$__light-goose:#E8EBED;
$__violet:#423B93;
$__violets:#1B2850;
$__violet__dark:#7367F0;
$__black:#000;
$__dark__ash__gray: #555;
$__dark__ash__grays:#212B36;
$__light__vampire: #f1f1f1;
$__light__vampires:#FAFBFE;
$__goose-grays: #B8BCC9;
$__light__smokey-gray:#5E5873;
$__light__smokey-grays:#292D32;
$__goose-greys:#565656;
$__sky__blue: #0d6efd; 
$__success__light: #26af48;
$__success__lights: #0F9D58;
$__purple__light: #c580ff;
$__successcolor: #28C76F;
$__dangercolor: #f62d51;
$__dangerred: #ff0000;
$__warningcolor: #FF9900;
$__warningcolors: #FC8543;
$__frog__blue: #989c9e;
$__fire__red: #EA5455;
$__grey__color:#8d8d8d;
$__gray__cloud: #999;
$__dark__gray__cloud: #9f9f9f;
$__cloud__gray: #eeeeee;
$__cloud__grays: #E0E0E0;
$__light__white: #fafafa;
$__light__whites: #E5E5E5;
$__dark__light: #f5f5f5;
$__dark__lights: #D8D8D8;
$__sandstones: #bdbdbd;
$__dark__blues: #32577D;
$__dark__violets: #092C4C;
$__dark__gray: #888888;
$__dark__grays: #B9B9C3;
$__light__battle__garys: #9e9e9e;
$__graybackblue: #38414A;
$__orangecolor: #012C90;
$__grey:#D9D9D9;
$__mute_color:#6B7A99;
$__black_color:#141432;
$__blue_black:#353570;
$__ash:#C8C8C8;
$__light__grey:#8ea0af;
$__dark__grey:#1d1d42;
$__black_grey: #97A2D2;
$__light__ash__grays:#F6F7F8;